<template>
    <div class="cajaSuperior">
        <b-container>
            <b-row>
            <b-col cols="12" md="5" lg="6">
                <div class="text-center">
                    <img src="../assets/iconoLogin.png" alt="Login" class="imgIcono mb-3">
                    <FormularioLoginPersona v-if="!tokenRecuperar"/>
                    <FormularioResetPersona v-if="tokenRecuperar"/>
                </div>
            </b-col>

            <!-- Formulario consulta -->
            <b-col cols="12" md="7" lg="6">
                <img src="../assets/mascotaLogin.png" alt="Logni" class="img-fluid">
            </b-col>
            </b-row>
         </b-container>
    </div>
</template>

<script>
    import FormularioLoginPersona from '../components/login/FormularioLoginPersona.vue'
    import FormularioResetPersona from '../components/login/FormularioResetPersona.vue'
    import { mapActions } from 'vuex'

    export default {
        name:'Login',
        components: {
            FormularioLoginPersona,
            FormularioResetPersona
        },
        data(){
            return {
                tokenRecuperar:false
            }
        },
        mounted(){
            if(this.$route.params.token){
                console.log(this.$route.params.token)
                this.tokenRecuperar = true
                this.setTokenRecueracion(this.$route.params.token)
            }
        },
        methods:{
            ...mapActions('personas', ['setTokenRecueracion']),
        },
    }
</script>

<style scoped>
    .cajaSuperior{
        background: #f3f3f5
    }

    .imgIcono{
        margin-top: 20px;
    }

    @media (min-width: 992px){
        .imgIcono{
            margin-top: 40px;
        }
    }
</style>