<template>
  <div>
    <b-form
      id="formularioLogin"
      @submit="enviarLogin"
      validate
      v-if="!registroPersona"
    >
      <b-form-row>
        <b-col cols="12" v-if="!recuperacion">
          <b-form-group
            id="group-login"
            label="Ingrese Identificación"
            label-for="login"
          >
            <b-form-input
              id="login"
              v-model="form.login"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="!recuperacion">
          <b-form-group
            id="group-password"
            label="Ingrese contraseña"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="recuperacion">
          <b-form-group
            id="group-email"
            label="Ingrese el correo de recuperación"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              :required="recuperacion"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="4" v-if="!recuperacion">
          <b-button
            variant="link"
            class="text-right mb-1"
            block
            size="md"
            @click="activarRegistro"
          >
            Registrarme
          </b-button>
        </b-col>

        <b-col cols="8" v-if="!recuperacion">
          <b-button
            variant="link"
            class="text-right mb-1"
            block
            size="md"
            @click="activarRecuperacion"
          >
            ¿Olvidé mi contraseña?
          </b-button>
        </b-col>

        <b-col cols="12" v-if="!recuperacion">
          <b-button
            type="submit"
            variant="primary"
            class="mr-2"
            block
            size="md"
          >
            Entrar
          </b-button>
        </b-col>

        <b-col cols="12" v-if="recuperacion">
          <p>Se enviará al correo un link para recuperar la contraseña</p>
        </b-col>

        <b-col cols="12" v-if="recuperacion">
          <b-button
            type="submit"
            variant="primary"
            class="mr-2 mb-2"
            block
            size="md"
            :disabled="form.email == null || form.email == ''"
          >
            Enviar Correo de recuperación
          </b-button>
        </b-col>

        <b-col cols="12" v-if="recuperacion">
          <b-button
            variant="primary"
            class="mr-2"
            block
            size="md"
            @click="cancelarRecuperar"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>

    <b-form
      id="formularioLogin"
      @submit="enviarRegistro"
      validate
      v-if="registroPersona"
    >
      <b-card header="Resgistro">
        <b-form-row>
          <b-col cols="12">
            <b-form-group
              id="group-identificacion"
              label="Ingrese número de identificación"
              label-for="identificacion"
            >
              <b-form-input
                id="identificacion"
                v-model="registro.identificacion"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              id="group-contrasena"
              label="Ingrese contraseña"
              label-for="contrasena"
            >
              <b-form-input
                id="contrasena"
                v-model="registro.contrasena"
                type="password"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              id="group-repassword"
              label="Confirme la contraseña"
              label-for="repassword"
            >
              <b-form-input
                id="repassword"
                v-model="registro.repassword"
                type="password"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              id="group-email"
              label="Ingrese el correo de recuperación"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="registro.email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-2 mb-2"
              block
              size="md"
              :disabled="registro.contrasena != registro.repassword"
            >
              Registrar
            </b-button>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mr-2"
              block
              size="md"
              @click="cancelarRegistro"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-form-row>
      </b-card>
    </b-form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { encontrarError } from "../../contantesErrores";

export default {
  name: "FormularioLoginPersona",
  data() {
    return {
      registroPersona: false,
      recuperacion: false,
      form: {
        login: "",
        password: "",
        email: "",
      },
      registro: {
        nombres: "",
        apellidos: "",
        fecha_nacimiento: "1900-01-01",
        ocupacion: "",
        identificacion: "",
        contrasena: "",
        repassword: "",
        direccion: "",
        finca_sector: "",
        barrio_vereda: "",
        telefono: "",
        email: "",
      },
    };
  },
  mounted(){
      localStorage.removeItem("usuario");
      localStorage.removeItem("persona");
  },
  computed: {
    ...mapState("personas", [
      "respuestaLogin",
      "respuestaForgot",
      "resultCreatePersona",
    ]),
  },
  methods: {
    ...mapActions("personas", ["login", "forgotPass", "createPersona"]),

    activarRecuperacion() {
      this.recuperacion = true;
    },

    async enviarLogin(ev) {
      ev.preventDefault();
      let datosEnviar;
      if (!this.recuperacion) {
        datosEnviar = {
          personalogin: this.form,
        };
        try {
          await this.login(datosEnviar);
          if (this.respuestaLogin.status == 200) {
            let persona = JSON.stringify(this.respuestaLogin.data[0]);
            localStorage.setItem("persona", persona);
            localStorage.removeItem("usuario");
            this.resetDatos();
            this.$router.push({ name: "misDatos" });
          }
        } catch (e) {
          encontrarError(this, e.data);
          
        }
      } else {
        datosEnviar = {
          email: this.form.email,
        };
        try {
          await this.forgotPass(datosEnviar);
          if (this.respuestaForgot.status == 200) {
            this.modalForgotOk();
          }
        } catch (e) {
          encontrarError(this, e.data);
        }
      }
    },

    modalForgotOk() {
      this.$bvModal
        .msgBoxOk("Se ha enviado el correo de recuperación", {
          title: "Confirmación",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          this.$router.go();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelarRecuperar(e) {
      e.preventDefault();
      this.recuperacion = false;
      this.resetDatos();
    },

    async enviarRegistro(ev) {
      ev.preventDefault();
      let datos = {
        persona: this.registro,
      };
      try {
        await this.createPersona(datos);
        if (this.resultCreatePersona.data.status == 200) {
          this.modalOk();
        }
      } catch (e) {
        // encontrarError(this, e.data);
        if(e.data.codigo == "23505"){
          if(e.data.message.indexOf('uk_identificacion') != -1){
            this.modalMensaje('Ya existe registrado un usuario con esta identificación')
          }else{
            this.modalMensaje('Ya existe registrado un usuario con este correo electrónico')
          }
        }
      }
    },

    activarRegistro() {
      this.registroPersona = true;
    },

    cancelarRegistro(e) {
      if (e) {
        e.preventDefault();
      }
      this.registroPersona = false;
      this.resetDatos()
    },

    modalOk() {
      this.$bvModal
        .msgBoxOk(
          "Los Datos fueron guardados correctamente. Por favor inicie sesión",
          {
            title: "Confirmación",
            size: "md",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        )
        .then(async () => {
          console.log("creado");
          this.cancelarRegistro();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    modalMensaje(mensaje) {
      this.$bvModal
        .msgBoxOk(mensaje, {
          title: "Error",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
        })
        .catch((err) => {
          console.log(err);
        });
    },  
    
    resetDatos(){
      this.form = {
        login: "",
        password: "",
        email: "",
      };
      this.registro= {
        nombres: "",
        apellidos: "",
        fecha_nacimiento: "1900-01-01",
        ocupacion: "",
        identificacion: "",
        contrasena: "",
        repassword: "",
        direccion: "",
        finca_sector: "",
        barrio_vereda: "",
        telefono: "",
        email: "",
      }
    }

  },
};
</script>
<style scoped>
#formularioLogin {
  width: 80%;
  margin-left: 10%;
}
@media (max-width: 992px) {
  #formularioLogin {
    width: 100%;
    margin-left: 0;
  }
}
</style>